// extracted by mini-css-extract-plugin
export var actionBox = "indicacao-module--actionBox--74d0c";
export var actionText = "indicacao-module--actionText--65755";
export var appSpace = "indicacao-module--appSpace--fd7ec";
export var appStore = "indicacao-module--appStore--f1a1b";
export var appStoreDiv = "indicacao-module--appStoreDiv--e05a1";
export var badInput = "indicacao-module--badInput--534c8";
export var bottomOrnament = "indicacao-module--bottomOrnament--f7278";
export var buttonCPF = "indicacao-module--buttonCPF--9acd1";
export var buttonCPFLabel = "indicacao-module--buttonCPFLabel--47c73";
export var buttonDownload = "indicacao-module--buttonDownload--57e7b";
export var done = "indicacao-module--done--e4af3";
export var doneBox = "indicacao-module--doneBox--ef5f5";
export var doneRemark = "indicacao-module--doneRemark--81aa7";
export var doneText = "indicacao-module--doneText--d5a16";
export var download = "indicacao-module--download--10ccc";
export var errorMessage = "indicacao-module--errorMessage--ca543";
export var hero = "indicacao-module--hero--d31a2";
export var heroBox = "indicacao-module--heroBox--ccb81";
export var hiddenMobile = "indicacao-module--hiddenMobile--256cc";
export var inputCPF = "indicacao-module--inputCPF--51b26";
export var know = "indicacao-module--know--4faa4";
export var labelCPF = "indicacao-module--labelCPF--b59fa";
export var leftOrnament = "indicacao-module--leftOrnament--13c4c";
export var logo = "indicacao-module--logo--e19d9";
export var mainInd = "indicacao-module--mainInd--94515";
export var rootInd = "indicacao-module--rootInd--14633";
export var showMobile = "indicacao-module--showMobile--32a42";
export var text = "indicacao-module--text--3b7b6";