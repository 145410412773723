import * as React from "react"
import { useEffect, useState } from "react"
import asyncAnalytics from "../components/analytics"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../styles/indicacao.module.css"
import QRCodeContainer from "../components/qrcode_container"

function CPFisValid(CPF) {
  const cleanCPF = (CPF.match(/\d+/g) ?? [""]).join('')
  let soma = 0;
  let resto = 0
  if (cleanCPF == "00000000000") return false;

  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cleanCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(cleanCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cleanCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto == 10) || (resto == 11)) resto = 0;
  if (resto != parseInt(cleanCPF.substring(10, 11))) return false;
  return true;
}

export default function page(props) {
  const newReferralUrl = "https://us-central1-dinerama-2912c.cloudfunctions.net/newReferral";
  const redirectHost = "https://go.dinerama.com.br/"
  const appStoreUrl = "https://apps.apple.com/br/app/dinerama/id1572283434"
  const googlePlayUrl = "https://play.google.com/store/apps/details?id=br.com.dinerama"
  const [state, setState] = useState({ cpf: "", valid: true, url: redirectHost + "?link=instalar" })

  useEffect(() => {
    var Inputmask = require('inputmask');
    new Inputmask.default().mask(document.querySelectorAll("input"));
    asyncAnalytics(props)

    if (state.url != redirectHost + window.location.search) setState({ ...state, url: redirectHost + window.location.search })
  }, [props, state]);
  const [done, setDone] = useState(false);
  return <div className={[styles.rootInd,(done ? styles.done : "")].join(" ")}>
    <div className={[styles.mainInd,(done ? styles.done : "")].join(" ")}>
      <img className={styles.logo} src="/images/logowhite.svg" alt="ornament"></img>

      <div className={styles.doneBox} hidden={!done}>
        <p className={styles.doneText}>
          Com a Dinerama você ganha<br />cashback em compras do dia a dia!
        </p>
        <img className={styles.doneRemark} src="/images/doneRemark.svg" alt="Venha ganhar tambem" />
        <QRCodeContainer url={state.url} size={150} />
        <a href={state.url}>
          <button className={styles.buttonDownload}>
            Baixar app Dinerama
          </button>
        </a>
        <div className={styles.download}>
          <a className={styles.appStoreDiv} href={appStoreUrl}><img className={styles.appStore} src="/images/modal_appstore.svg" alt="logo da app store" /></a>
          <div className={styles.appSpace} />
          <a className={styles.appStoreDiv} href={googlePlayUrl}><img className={styles.appStore} src="/images/modal_playstore.svg" alt="logo da play store" /></a>
        </div>
      </div>
      <div className={styles.actionBox} hidden={done}>
        <p className={styles.actionText} >
          Venha para a Dinerama e <br className={styles.hiddenMobile} />
          <span style={{ fontWeight: 700 }}>ganhe cashback</span><br className={styles.showMobile} /> em suas <br className={styles.hiddenMobile} />
          compras no supermercado
        </p>
        <label className={styles.labelCPF}>Digite o seu CPF:</label>
        <input
          placeholder="000.000.000-00"
          data-inputmask="'mask': '999.999.999-99'"
          data-mask-selectonfocus="true"
          className={[styles.inputCPF, CPFisValid(state.cpf) ? "": styles.badInput].join(" ")} 
          type="text"
          value={state.cpf}
          onChange={(t) => { setState({ ...state, cpf: t.target.value }) }}
        />
        <p className={styles.errorMessage} hidden={state.cpf == ""  || CPFisValid(state.cpf)}>CPF inválido</p>
        <button
          className={styles.buttonCPF}
          onClick={() => {
            setDone(true);
            fetch(newReferralUrl, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                data: {
                  cpf: state.cpf,
                  referralId: new URLSearchParams(window.location.search).get("referralId") ?? "bWlzc2luZ1BhcmFtZXRlcg"
                }
              }),
            });
          }
          }
        >
          <img className={styles.buttonCPFLabel} src="/images/button-indica.svg" alt="Quero ganhar cashback" />
        </button>
      </div>
      <img className={styles.bottomOrnament} src={done ? "/images/yellowOrnament.svg" : "/images/ornament.svg"} alt="ornament" />
      <img className={styles.leftOrnament} src="/images/ornament.svg" alt="ornament" />
      <p className={styles.text}>
        <span style={{ fontWeight: 700 }}>Você recebeu uma <br />
          indicação </span>para<br />
        ganhar cashback<br />
        com a Dinerama</p>
      <a href="/" className={styles.know}>Conheça <span className={styles.hiddenMobile}>a Dinerama</span></a>
      <div className={styles.heroBox}>
        <StaticImage
          className={styles.hero}
          imgClassName={"png"}
          src="../../static/images/indica-hero.png"
          alt="smartphone demonstrando como funciona o aplicativo"
          quality={100}
          objectFit="contain"
          objectPosition={"center bottom"}
        />
      </div>

    </div>
  </div>
}